import './App.css';
import ProjectPage from './components/ProjectPage'

function App() {
  return (
    <div className="container">
      <ProjectPage />
    </div>
  );
}

export default App;